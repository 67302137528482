import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { Modal } from 'antd';
import { customBaseUrl, httpPost } from '../../services/http';
import { hideLoader, showLoader } from '../../../utils/loader';

const BulkUpload = ({ showModal, hideModal, getTotalPayments, title, className, getPayments }) => {
  const [file, setFile] = useState('');
  const [amount, setAmount] = useState(0);

  const handleSubmit = async (e) => {
    e.preventDefault();
    showLoader();

    const formdata = new FormData();
    formdata.append('file', file);
    let res = await httpPost(`${customBaseUrl}/api/v1/excel/payments`, formdata)

   

    if (res?.status) {
      hideLoader();
      Swal.fire('Done', res?.message, 'success');
      setFile('');
      hideModal(false);
      getTotalPayments();
      getPayments();
    } else {
      hideLoader();
      Swal.fire('Error',res.message, 'error');
    }
  };

  return (
    <Modal
      className={className}
      title={'Bulk Upload of payments'}
      centered
      visible={showModal}
      onOk={() => hideModal(false)}
      onCancel={() => hideModal(false)}
      width={500}
      footer={0}
    >
      <form class='flex flex-col mx-auto  ' novalidate>
      
        <div className='col-10 mt-5 mb-3 mx-auto'>
          <div class='input-group'>
            <input
              type='file'
              class='form-control'
              id='inputGroupFile02'
              onChange={(e) => setFile(e.target.files[0])}
            />
           
            <div class='invalid-feedback'>File is required.</div>
          </div>
        </div>

        <div class='mx-auto'>
          <button class='px-6 py-2 text-white bg-red-500 rounded-full baseline hover:bg-red-300 ' type='submit' onClick={handleSubmit}>
            Submit form
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default BulkUpload;
