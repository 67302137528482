import React, {useEffect, useState} from "react";
import {Table,Row,Col,Card, Button, Modal, Form, Input, Select, message} from "antd";
import Layout from "../Layout"
import axios from "axios";
import RecordPayment from "./modals/recordpayment";
import { customBaseUrl, httpGet, httpPost } from "../services/http";
import { currencyFormat, hideLoader, showLoader } from "../../utils/loader";
import Swal from "sweetalert2";
import AntDropdown from "../customDropdown/dropdown";
import BulkUpload from "./modals/bulkpayments";
import { Pagination } from "@mui/material";
import moment from "moment";
import { CSVLink } from "react-csv";



const Payments = () => {
    const initial = 
    {
      ippis: null,
    legacyid: null,
    name: null,
    element: 'KADUNA LAND LOAN',
    amount: null,
    period: null,
    command: null,
    }
    const [customers, setCustomers] = useState([])
    const [viewPaymentModal, setViewPaymentModal] = useState(false)
    const [showBulkUpload,setShowBulkUpload] = useState(false)
    const [payData, setPayData] = useState(initial)
    const [searchText, setSeachText] = useState('')
    const [totalPay, setTotalPay] = useState(0)
    const [metaData, setMetaData] = useState(0);
    const [pages, setPages] = useState(0)
    const [ pagination, setPagination] = useState({size:50, page:1});
    const [searchCustomers, setSearchCustomers] = useState([])
    const [filterAmount, setFilterAmount] = useState(null);
    const [filteredData, setFilteredData] = useState([])
    const columns = [
        {
            title: 'Reference',
            dataIndex: 'ref',
            key: 'ref',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'owner',
        },
        {
            title: 'IPPIS',
            dataIndex: 'ippis',
            key: 'plotno',
            render: (text,row) => <a href={`#/customer/${row?.ippis}`}>{row?.ippis}</a>,
        },
       
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'price',
        },     

       
        {
            title: 'Date',
            dataIndex: 'period',
            key: 'period',
            render: (text, row) => moment(row?.period).format('MM-YYYY')
        },
        
        /*{
            title: 'No of Plots',
            dataIndex: 'receiver',
            key: 'nofoplots',
        },*/
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span>
                    <Button type="primary" onClick={() => editCustomer(record)}>Edit</Button>
                    {//<Button type="danger" onClick={() => deleteCustomer(record)}>Delete</Button>
                    }
                </span>
            ),
        }
    ];

    const column1 = [

        {
            title: 'Name',
            dataIndex: 'name',
            key: 'owner',
        },
        {
            title: 'IPPIS',
            dataIndex: 'ippis',
            key: 'plotno',
            render: (text,row) => <a href={`#/customer/${row?.ippis}`}>{row?.ippis}</a>,
        },
       
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'price',
        },     
    ]
    const editCustomer = (record) => {
        console.log(record)
    }
    const deleteCustomer = (record) => {
        console.log(record)
    }

    const handleSubmitPayment = async () => {
     //   return alert(JSON.stringify(payData))
        showLoader();
        let res = await httpPost(`${customBaseUrl}/api/v1/payments/single`,
        payData
        )

        if(res?.status){
            hideLoader();
            setViewPaymentModal()
            getPayments();
            Swal.fire('Successful', res.message, 'done');
        }else{
            hideLoader();
            Swal.fire('Failed','payment failed', 'Error');
        }
        
    }
    const handleChange = (event, value) => {
        setPagination({...pagination, page: value})
        // eslint-disable-next-line
      };

    const getPayments = async (page, size) => {
        const limit = size ?? pagination.size;
        const pages = page ?? pagination.page;

            showLoader();
            let res = await httpGet(`${customBaseUrl}/api/v1/payments/paginated?page=${pages - 1}&size=${limit}`)
            if(res?.status){
                hideLoader();
                console.log('PAY', res?.data)
                setCustomers(res.data)
                setMetaData(res?.totalItems)
                setPages(res?.totalPages);
            }else{
                hideLoader();
                console.log(res)    

            }
    }
    const filterPayments = async (page, size) => {
        const limit = size ?? pagination.size;
        const pages = page ?? pagination.page;

            showLoader();
            let res = await httpGet(`${customBaseUrl}/api/v1/payments/range/${filterAmount}`)
            if(res?.status){
                hideLoader();
                console.log('PAY', res?.data)
                setFilteredData(res.data)
                // setMetaData(res?.totalItems)
                // setPages(res?.totalPages);
            }else{
                hideLoader();
                console.log(res)    

            }
    }
    const searchPayments = async () => {
            if (!searchText) return Swal.fire('Error', 'Search field is empty');
            showLoader();
            let res = await httpGet(`${customBaseUrl}/api/v1/payments/single/customer/${searchText}`)
            if(res?.status){
                hideLoader();
                console.log('jjjj', res?.data);
                setSearchCustomers(res.data)                
            }else{
                hideLoader();
                console.log(res)    

            }
    }
    const getTotalPayments = async () => {
        showLoader();
        let res = await httpGet(`${customBaseUrl}/api/v1/payments/totalpayments`)
        if(res?.status){
            hideLoader();
            console.log(res.data)
            setTotalPay(res.data[0]?.sum)
        }else{
            hideLoader();
            console.log(res)

        }
}
    useEffect(() => {
        getTotalPayments();
        getPayments();
    },[pagination.page])

    useEffect(() => {
        if (!filterAmount) {
            setFilteredData([]);
        }
    }, [filterAmount])
    const addOptions = [
        "Single Payment",
        "Bulk Payment",
      ];
      const handleAddDropdown = (item) => {
        if (item === "Single Payment") setViewPaymentModal(true);
        if (item === "Bulk Payment") setShowBulkUpload(true);
        //if (item === "Send Using Webforms") setShowTransferForms(true);
      };
      useEffect(()=>{
        if(!searchText){
            setSearchCustomers([]);
        }
      }, [searchText]);
      
    return(
        <Layout>
             <div style={{margin:40}}>
                <Row>
                    <div>
                        <input placeholder="User Id" onChange={(e)=>{
                            setSeachText(e.target.value)                       
                        }
                            } style={{marginLeft:'5%'}}/>
                        <Button type="primary" style={{marginLeft:'10px'}} onClick={searchPayments}>Search</Button>
                    </div>
                    <div>
                     <input placeholder="Filter Amount" onChange={(e)=>{
                        setFilterAmount(e.target.value)                       
                    }
                        } style={{marginLeft:'5%'}}/>
                     <Button type="primary" style={{marginLeft:'10px'}} onClick={filterPayments}>Filter</Button>
                    </div>
                    <div style={{position:'absolute',  right:15,float:'right'}}>
                        <Button type="primary" style={{marginLeft:'10px'}}>Export</Button>
                        <AntDropdown
                          dropDownOptions={addOptions}
                          handleDropdownAction={handleAddDropdown}
                        >
                        <Button type="primary" style={{marginLeft:'10px'}} >Record Payments</Button>
                        </AntDropdown>
                    </div>
                </Row>
            </div>
            <div>
                <Row>
                    <Col span={6}>
                        <Card>
                                <h1>No. of Transaction</h1>  
                                <p><h1>{metaData}</h1></p>  
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card span={2}>
                                <h1>Total Amount</h1>  
                                <p><h1 style={{textAlign:'center'}}>{currencyFormat(Number(totalPay))}</h1></p>  
                        </Card >
                    </Col>
                   
                    </Row>
                    
            </div>
        {filteredData?.length > 0 &&
        <button 
            className="btn"
            style={{marginRight:20}}
            // onClick={()=>setShowStatement(true)}
          >          
        <CSVLink
          data={filteredData}
          style={{            
            color: "#fff",
            display: "flex",
            alignItems: "center",
            textDecoration:"none",
            borderColor: "red",
            background: "#000"
          }}
          filename={`payments of ${filterAmount} and above.csv`}
          onClick={(event, done) => {
             //event.preventDefault();
              // handleFilterOperation();
            //done()           
          }}
        >
          DOWNLOAD AS EXCEL
        </CSVLink>
          </button>}
            {filteredData?.length <= 0 ? 
                <div style={{margin:20}}>
                    <Table columns={columns} pagination={false} dataSource={(searchText && searchCustomers.length)? searchCustomers:customers} />
                    <Pagination
                        count={pages}
                        page={pagination.page}
                        onChange={handleChange}
                    />
                </div> :
                <div style={{margin:20}}>
                <Table columns={column1}  dataSource={filteredData} />
            </div>
            }

            {viewPaymentModal &&<RecordPayment 
                                    initial={initial}
                                    hideModal={setViewPaymentModal}
                                    showModal={viewPaymentModal}
                                    setPayData={setPayData}
                                    payData={payData}
                                    handleSubmit={handleSubmitPayment}
                                    />}

        {showBulkUpload &&<BulkUpload getPayments={getPayments} getTotalPayments={getTotalPayments} hideModal={setShowBulkUpload} showModal={showBulkUpload}/>}       
        
           
        </Layout>
    )
}

export default Payments;


