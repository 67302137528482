import React, { useState, useEffect } from 'react';
import { Modal, Button, DatePicker, Row, Col } from 'antd';
import moment from 'moment'
import Wrapper from './style';
import { StepForwardOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import { customBaseUrl, httpGet } from '../../services/http';
import axios from 'axios';
import { hideLoader, showLoader } from '../../../utils/loader';

const RecordPayment = ({ showModal, hideModal, initial, setPayData, payData, handleSubmit }) => {
 
    const [searchData, setSearchData] = useState([])
    const [phone, setPhone] = useState('')


    const getPayment = async()=>{
        
    }

    const searchUser= async(e)=>{
        showLoader();
        let res = await httpGet(`${customBaseUrl}/api/v1/customers/userdetails/${phone}`)

        if(res.status){
            hideLoader();
            console.log(res.data)
            setSearchData(res.data)
            const {name, ippis} = res?.data[0]
            setPayData({...payData, name, ippis})
        }else{
            hideLoader();
            console.log(res)
        }

    }


  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={null}
      centered
      onCancel={() => {
        hideModal(false);
        setPayData(initial);
      }}

    >
      <Wrapper>
        <div className="cw-content">

          <div >


            <div className="input-content">
              <div className="label">Record Payment</div>
             
            </div>
            <div className="label">search customer by ID</div>
            <Row>
                <Col>
            <div className="input-content">
              <input value={phone} onChange={(e)=>{setPhone(e.target.value); setSearchData([])}}/>
            </div>
                </Col>
                <Col>
                <Button
                    style={{
                        fontSize: '8px',
                        alignItems: 'center',
                        width: 70
                    }}
                    onClick={searchUser}
                    >Search</Button>
                </Col>
            </Row>
            <div className="input-content">
              <div className="label">Select Customer</div>
              <select  className='label border border-dark col-md-10' onChange={(e)=>{  setPayData({...payData, siteid:JSON.parse(e.target.value).siteid, plotno:JSON.parse(e.target.value).plotno, customerid:JSON.parse(e.target.value).customerid })}}>
                <option value=''>Show All</option> 
                {
                searchData?.map(item=>(
                    <option value={JSON.stringify(item)}>{item.name}</option>
                ))
                }              
              </select>
            </div>
            <div className="input-content label">
              <div className="label">Name</div>
              <input value={payData?.name}  onChange={(e)=>setPayData({...payData, name:e.target.value})} />
            </div>
            <div className="input-content label">
              <div className="label">IPPIS</div>
              <input value={payData?.ippis}  onChange={(e)=>setPayData({...payData, ippis:e.target.value})} />
            </div>
            <div className="input-content label">
              <div className="label">Amount</div>
              <input value={payData.amount}  onChange={(e)=>setPayData({...payData, amount:e.target.value})} />
            </div>
            <div className="input-content label">
              <div className="label">Period</div>
              <input type='date' value={payData?.period}  onChange={(e)=>setPayData({...payData, period: e.target.value})} />
            </div>
            <div className="input-content label">
              <div className="label">Command</div>
              <input value={payData?.command}  onChange={(e)=>setPayData({...payData, command:e.target.value})} />
            </div>
            
          </div>

        </div>
      </Wrapper>

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyItems: 'stretch',
          justifyContent: 'stretch',
        }}
      >
       
        <Button
          style={{
            background: '#FF4B01',
            color: '#fff',
            fontSize: '8px',
            alignItems: 'center',
            width: 70
          }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default RecordPayment;
