import styled from 'styled-components';


export const LoginWrapper = styled.div`
  input:focus {
    outline: none !important;
    border: 1.5px solid #e7472c !important;
    border-radius: 2px;
  }
 
`;

export const ForgotPasswordWrapper = styled.div`
  input:focus {
    outline: none !important;
    border: 1.5px solid #e7472c !important;
  }
 
`;

const style = {
  login: {
    container: {
      height: '100vh',
      display: 'flex',
    },
    left: {
      //background: 'rgba(231, 71, 44, 0.31)',
      marginTop: "auto",
      marginBottom: "auto",
      background: '#b4f7e3',
      width: '50%',
      height: 'inherit',
      image: {
        height: '400px',
      },
      logo: {
        height: '35px',
        position: 'absolute',
        top: '30px',
        left: '40px',
      },
    },
    right: {
      width: '50%',
      height: 'inherit',
    },
    formContainer: {
      width: '70%',
    },
    input: {
      borderRadius: '7px',
      boxSizing: 'border-box',
    },
  
  },
};

export default style;
