import React,{useState, useEffect} from "react";
import {Col, Row, Card} from 'antd';
import { Layout, Menu } from 'antd';
import "./style.css";
import randomColor from "randomcolor";
import axios from "axios";
import SiteLayout from "../Layout"
import mcity from "../../assets/plot2.jpg";
import mjos from "../../assets/plot1.png";
import mcity1 from "../../assets/zazz1.jpg";
import mcity2 from "../../assets/zazz-layout-size.jpg"
import { customBaseUrl, httpGet } from "../services/http";
import { hideLoader, showLoader } from "../../utils/loader";

const { Header, Content, Footer, Sider } = Layout;


var ImageMapper = require("react-image-mapper");
function getRandomColor() {
  return randomColor({
    luminosity: "dark",
    hue: `yellow`
  });
}



const Sites = () => {
const [mouse, setMouse] = useState({ hoveredArea: null, msg: null, moveMsg: null})
const [MAP, setMap] = useState({
    name: "Zazzau Site",
    areas:[]})
const [selectedSite, setSelectedSite] = useState(1)

const load=()=> {
    setMouse({...mouse, msg: "No plot selected"})
  }
  const clicked = (area) => {

	  console.log('area',area);
    setMouse({...mouse, hoveredArea: area, msg: "Plot selected"+area.plotno})

  }
  const clickedOutside=(evt) =>{

   
  }

 const  moveOnImage=(evt) =>{
   
  
  }
  const enterArea=(area) =>{

    console.log(area);
   setMouse({...mouse,  msg: area.plotno})
  }

 const leaveArea=(area)=> {
  
  }


  const moveOnArea =(area, evt) => {
    console.log(area);
    const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
    setMouse({...mouse,   moveMsg: `You moved on ${area.shape} ${
        area.name
      } at coords ${JSON.stringify(coords)} !`
    })  
   
  }

const  getTipPosition=(area) =>{
    console.log(area);
    return {
      top: `${area.center[1] * 0.36 - 100}px`,
      left: `${area.center[0] * 0.36}px`
    };
  }

  const getPlots = async() => {
      showLoader();
    setMouse({ })
    let res = await httpGet(`${customBaseUrl}/api/v1/sites/plots/${selectedSite}`)

    if(res?.status){
      hideLoader();
      let k = res.data;
      let newD = await k?.map(item=>{
          return {...item, coords:item.coords?item.coords:[191,410,221,467,300,443,276,418,265,388], fillColor: getRandomColor(), preFillColor:item.status==='taken'?'green': 'blown'}
      })
      console.log('newD',newD);
      setMap( {...MAP, areas:newD})
       console.log(res.data);
    }else{
      hideLoader();
      setMap( {...MAP, areas:[]})
      console.log(res)
    }
  }

  useEffect(()=>{
    setMap( {...MAP, areas:[]})
    getPlots()
  },[selectedSite])

  return (
    <SiteLayout>
      <Layout hasSider>
          <Sider
            style={{
              overflow: 'auto',
              height: '100%',
              position: 'fixed',
              right: 0,
              top: 0,
              bottom: 0,
              marginTop: 50
            }}
          >
            <div className="logo" />

            <Card title="Plot Details" bordered={false}style={{width:600}} >
              
              <Row>
                <Col span={4} >Site </Col>
                <Col> {mouse?.hoveredArea?.site}</Col>
              </Row>
              <Row>
                <Col span={4}>PLot no </Col>
                <Col>{mouse?.hoveredArea?.plotno}</Col>
              </Row>
              <Row>
                <Col span={4}>Status </Col>
                <Col>{mouse?.hoveredArea?.status}</Col>
              </Row>
              <Row>
                <Col span={4}>Price</Col>
                <Col>{mouse?.hoveredArea?.price}</Col>
              </Row>
              <Row>
                <Col span={4}>Customer Name</Col>
                <Col>{mouse?.hoveredArea?.customername}</Col>
              </Row>
              <Row>
                <Col span={4}>Amount Received</Col>
                <Col>{mouse?.hoveredArea?.amountreceived}</Col>
              </Row>
              <Row>
                <Col span={4}>Status</Col>
                <Col>{mouse?.hoveredArea?.status}</Col>
              </Row>
              <Row>
                <Col span={4}>Transaction Date</Col>
                <Col>{mouse?.hoveredArea?.soldat}</Col>
              </Row>             

                  </Card>

          </Sider>
          <Sider
            style={{
              overflow: 'auto',
              position: 'fixed',
              left: 0,
              top: 0,
              bottom: 0,
            }}
          >
            <div className="logo" />

            <Card title="Plot Details" bordered={false}>
            <div className="site-container">
            <select onChange={(e)=>setSelectedSite(e.target.value)}>
                <option value={1}>Millenium City</option>
                <option value={2}>Maraban Jos</option>
            </select>
        </div>
            </Card>

          </Sider>

<Layout
  className="site-layout"
  style={{
    marginLeft: 150,
  }}
>
 
  <Content
    style={{
      margin: '24px 16px 0',
      overflow: 'initial',
    }}
  >

        
    <div>
          
      <ImageMapper
        src={selectedSite==1?mcity2:mjos}
        map={MAP}
        width={selectedSite==1?800:499}
        imgWidth={selectedSite==1?932:719}
        onLoad={() => load()}
        onClick={area => clicked(area)}
        onMouseEnter={area => enterArea(area)}
        onMouseLeave={area => leaveArea(area)}
        onMouseMove={(area, _, evt) => moveOnArea(area, evt)}
        onImageClick={evt => clickedOutside(evt)}
        onImageMouseMove={evt => moveOnImage(evt)}
        lineWidth={0}
        strokeColor={"white"}
      />
      {/* {MAP.areas.map((area, index) => {
        //console.log()
        return (
          <span
            className="tooltip"
            key={index}
            style={{ ...this.getTooltipStyle(area.coords) }}
          />
        );
      })}
      {/* {this.state.hoveredArea && (
       
      )} */}

      {/*mouse.hoveredArea && (
        <span
          className="tooltip"
          style={{ ...getTipPosition(mouse.hoveredArea) }}
        />
      )*/}
       
    </div>
  
  </Content>
  
</Layout>

</Layout>

    </SiteLayout>
  );

}



export default Sites;
