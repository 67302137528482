import { BrowserRouter as Router, Route, Routes, HashRouter } from 'react-router-dom';
// import { createBrowserHistory } from "history";

import logo from './logo.svg';
import './App.css';
import Home from './components/Homepage';
import slugs from './components/resources/slugs';
import Sites from './components/Site';
import Customers from './components/Customers';
import Plots from './components/Plots';
import Payments from './components/payments';
import Login from './components/login';
import Profile from './components/profile';
import CustomerDetails from './components/Customers/customerDetails';

function App() {

 // const hist = createBrowserHistory();

  return (
      <HashRouter >
          <Routes>
        <Route exact path="/home" element={<Home />} />
        <Route exact path={slugs.sites} element={<Sites/>} />
        <Route exact path={slugs.customers} element={<Customers/>} />
        <Route exact path={slugs.plots} element={<Plots/>} />
        <Route exact path={slugs.payments} element={<Payments/>} />
        <Route exact path={slugs.login} element={<Login/>} />
        <Route exact path={slugs.profile} element={<Profile/>} />
        <Route exact path={slugs.customerDetails} element={<CustomerDetails/>} />






        <Route path="/*" element={<Home />} />
        </Routes>
        </HashRouter>

  );
}

export default App;
