import React, { useState } from "react";
import { Input, Row,Form,  Button, Typography, Carousel } from "antd";
import { useNavigate } from "react-router-dom";
//import { useFormik,Formik, Field,Form, useField } from 'formik';
import * as Yup from 'yup';
import style, { LoginWrapper } from "./style";
import { customBaseUrl, httpPost } from "../services/http";
import Swal from "sweetalert2";
import { hideLoader, showLoader } from "../../utils/loader";
import Img from "../../assets/img5.jpg"
import Img6 from "../../assets/img6.jpg"
import Img7 from "../../assets/img7.jpg"


const contentStyle = {
  height: '100%',
  color: '#fff',
  lineHeight: '360px',
  textAlign: 'center',
  background: '#364d79',
};



export default function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});





  const onFinish = async () => {
    showLoader();
    let res =await httpPost(`${customBaseUrl}/api/v1/login`,{email:data?.emailOrPhoneNumber})
    if(res.status){
        console.log(res)
        hideLoader();
        window.location.href=`/`
        localStorage.setItem('isAdmin',res?.data?.isAdmin)
        localStorage.setItem('login',true)
        localStorage.setItem('profile', JSON.stringify(res.data))



    }else{
        hideLoader();
        Swal.fire('error', res?.message)
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

 

  return (
    <LoginWrapper className="h-100">
      <div style={style.login.container}>
        <div style={style.login.left}  className="div-left my-auto mt-50">
        <Carousel autoplay>
    <div className="mt-30">
      <img src={Img} />
    </div>
    <div>
    <img src={Img6} />
    </div>
    <div>
    <img src={Img7}  />
    </div>
    <div>
    <img src={Img} />
    </div>
  </Carousel>
        </div>

        <div className="div-right" style={style.login.right}>
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{ minHeight: "100vh" }}
          >
            <div style={style.login.formContainer}>
              <h1
                style={{
                  textAlign: "center",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "53px",
                }}
              >
                Sign in
              </h1>

              <Form
                name="basic"
                layout="vertical"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item label="USER ID" name="username">
                  <Input
                    size="large"
                    style={style.login.input}
                    className="input-focus"
                    onChange={(e) =>
                      setData({ ...data, emailOrPhoneNumber: e.target.value })
                    }
                  />
                </Form.Item>

                <Form.Item label="Password" name="password">
                  <Input.Password
                    size="large"
                    style={style.login.input}
                    onChange={(e) =>
                      setData({ ...data, password: e.target.value })
                    }
                  />
                </Form.Item>

                  <a href = "/forgot_password" >Forgot password?</a>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    size="large"
                    block
                    loading={loading}
                    style={style.login.button}
                  >
                    Sign in
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Row>
        </div>
      </div>
    </LoginWrapper>
  );
}
