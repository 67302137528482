const signOut =()=> alert('lll')//localStorage.setItem('login',false)

const slugs = {
    // private
    dashboard: "/home",
    sites: "/sites",
    mcity: "/sites/millenium-city",
    mjos: "/sites/maraban-jos",
    customers: "/customers",
    plots: "/plots",
    payments: "/payments",
 // auth
    login: "/login",
    forgotPassword: "/forgot_password",
    changePasswordFromEmail: "/forgot-password",
    logout: "/login",
    profile: "/profile",
    customerDetails: "/customer/:id",


   
    // wrong url
    page404: "/",
  };

  export default slugs;
  