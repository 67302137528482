import React from "react";
import { Avatar, Button, Card, Row, Table } from "antd";
import { MoreOutlined, ProfileOutlined, UserOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useEffect } from "react";
import { customBaseUrl, httpGet } from "../services/http";
import Layout from "../Layout"
import { useParams } from "react-router-dom";
import { currencyFormat, hideLoader, showLoader } from "../../utils/loader";
import fil from "../../c-o/123.pdf"
import im from "../../c-o/234.jpg"
import { EditOffRounded } from "@mui/icons-material";
import {EditOutlined} from '@ant-design/icons'
import AddProfilePic from "../modals/addProfile";
import ViewDoc from "./viewDoc";
import statementPDF from "../payments/printStatement";


const CustomerDetails=()=>{
    const { id: userId } = useParams();

    const [plots, setPlots] = useState([])
    const [payments, setPayments] = useState([])
    const [details, setDetails] = useState([])
    const [ showPropicModal, setShowProfilePicModal] = useState(false)
    const [showCert, setShowCert] = useState(false)
    const [totalPayment, setTotalPayment] = useState(0)


    const profile= JSON.parse(localStorage.getItem('profile'))

    const paymentColumns = [
        {
            title: 'Ref',
            dataIndex: 'ref',
            key: 'plotno',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Reason',
            dataIndex: 'element',
            key: 'site',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'price',
        },   
        {
            title: 'Period',
            dataIndex: 'period',
            key: 'date',
            //render: text => `${new Date(text).getDay()}-${new Date(text).getMonth()}-${new Date(text).getFullYear()}`
        },       
      
        
    ]
    const columns = [
        {
            title: 'Site',
            dataIndex: 'location',
            key: 'site',
            render: (text)=> text==1?'Damishi': text==2?'Maraban Jos':text==3?'Kafin Ganye':text
        },
        {
            title: 'Block',
            dataIndex: 'block',
            key: 'site',
        },
        {
            title: 'Plot No',
            dataIndex: 'plotno',
            key: 'plotno',
            render: text => <a>{text}</a>,
        },
      
        /*{
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        },       
        {
            title: 'Amount paid',
            dataIndex: 'amount',
            key: 'amntpaid',
        }  */    
        
    ]

    const getSites = async()=> {
        showLoader();
        let res = await httpGet(`${customBaseUrl}/api/v1/customers/plots/${userId}`)
        if(res.status){
            console.log('user plots', res.data)
            hideLoader();
            setPlots(res.data)
        }else{
            hideLoader();
        }
    }

    const getUserInfo = async()=>{
        showLoader();
        let res = await httpGet(`${customBaseUrl}/api/v1/customers/profile/${userId}`)
        if(res.status){
            console.log('all', res.data)
            hideLoader();
            setDetails(res.data[0])
        }else{
            hideLoader();
        }
    }

    const searchPayments = async () => {
        showLoader();
        let res = await httpGet(`${customBaseUrl}/api/v1/payments/customer/total/${userId}`)
        if(res?.status){
            hideLoader();
            console.log('jjjj', res?.data);
            setTotalPayment(res.data[0].sum)                
        }else{
            hideLoader();
            console.log(res)    

        }
}
    const getPayments = async () => {

        let res =await httpGet(`${customBaseUrl}/api/v1/payments/customer/${userId}`)
        if(res.status){
            console.log('payment', res.data)
            setPayments(res.data)
        }else{
            console.log(res)
        }
    }

    useEffect(()=>{
        getSites();
        getPayments();
        getUserInfo();
        searchPayments();
    },[])

    const onButtonClick = () => {
        // using Java Script method to get PDF file
        fetch('../../c-o/123.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'SamplePDF.pdf';
                alink.click();
            })
        })
    }
return (
    <Layout>
        <div className="container flex w-full flex-col bg-gray-100">
                <div className="flex flex-col  bg-[#2D136F]">
                    <div className=" flex  item-center bg-white w-20 h-20 mx-auto  mt-10 shadow-2xl rounded-full ">
                        {details?.imgurl?<img className="w-30 h-30 rounded-full " src={details.imgurl}/>:
                        <Avatar shape='circle' size={80}  icon={<UserOutlined />}/>}
                        <div className="z-20 bg-green-500 my-auto mx-auto">
                        <EditOutlined size={90} onClick={()=>setShowProfilePicModal(true)}/>

                    </div>

                    </div>
                    <div  className="item-center text-center  ">
                        <h1 className="text-white">
                            {details?.name}
                        </h1>
                        <h1 className="text-white">
                        +234{details?.phoneNumber}
                        </h1>
                        <h1 className="text-white">
                            Email@ns.com.ng
                        </h1>
                    </div>
                    
                
                    <marquee direction="left">      
                    <h1 className="text-red-500">        
                        Outstanding Infrastructure Payment N220,000
                    </h1>
                    </marquee>

                </div>

                <div className="container flex flex-col items-center justify-center
            px-6 py-12  space-x-6 mx-auto space-y-4 md:flex-row md:space-y-0">
            <div>
               
                        <Card >
                                <h1>No of Plots</h1>  
                                <p><h1 className="mx-auto text-center">0</h1></p>  
                        </Card>
        
            </div>
           {/* <div>
                        <Card>
                                <h1>Total Cost of Plots</h1>  
                                <p><h1 className="mx-auto text-center">{0}</h1></p>  
                        </Card>
                    
</div>*/}
            <div>
                        <Card>
                                <h1>Total Amount Paid</h1>  
                                <p><h1 className="mx-auto text-center">{currencyFormat(Number(totalPayment))}</h1></p>  
                        </Card>
                    
            </div>
           
           
            </div>
            <div className="ml-20 my-10">
                Document {' '}
            
               <button onClick={()=>setShowCert(true)}>View</button>

            </div>
                <div >
                    <h1 className="text-2xl ml-20 font-sans">
                        Plots
                    </h1>
                    <div className="mx-20">
                    <Table columns={columns} dataSource={plots} />
                    </div>
                </div>
                <img src={require('../../c-o/234.jpg')} />

                <div>
                    <Row>
                <h1 className="text-2xl ml-20 font-sans">
                        Payments
                </h1>
                <Button
                onClick={() => statementPDF(payments, details)}
                variant="contained" 
                color="primary"
                style={{marginTop: 15, marginLeft: 20}}
                >
                    Download statement
                </Button>
                </Row>
                    <div className="mx-20">
                    <Table columns={paymentColumns} dataSource={payments} />
                    </div>
                </div>
        </div>

        {
            showPropicModal &&
            <AddProfilePic getUserInfo={getUserInfo} details={details} showModal={showPropicModal} hideModal={setShowProfilePicModal} userid={details?.ippis} name={details?.name}/>
        }
         {
            showCert &&
            <ViewDoc  title={details?.ippis} showModal={showCert} hideModal={setShowCert}/>
        }
    </Layout>
)

}

export default CustomerDetails;