import React, { useState, useEffect } from 'react';
import { Modal, Button, DatePicker, Row, Col } from 'antd';
import moment from 'moment'
import Wrapper from './style';
import { StepForwardOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import axios from 'axios';
import { hideLoader, showLoader } from '../../utils/loader';
import { customBaseUrl, httpGet } from '../services/http';

const AddCustomer = ({ showModal, hideModal, initial, setPayData, payData, handleSubmit }) => {
 
    const [searchData, setSearchData] = useState([])
    const [phone, setPhone] = useState('')


    const getPayment = async()=>{
        
    }

    const searchUser= async(e)=>{
        showLoader();
        let res = await httpGet(`${customBaseUrl}/api/v1/customers/userdetails/${phone}`)

        if(res.status){
            hideLoader();
            console.log(res.data)
            setSearchData(res.data)
            const {name, ippis} = res?.data[0]
            setPayData({...payData, name, ippis})
        }else{
            hideLoader();
            console.log(res)
        }

    }


  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={null}
      centered
      onCancel={() => {
        hideModal(false);
        setPayData(initial);
      }}

    >
      <Wrapper>
        <div className="cw-content">

          <div >


            <div className="input-content">
              <div className="label">Add Customer</div>
             
            </div>
            <div className="input-content label">
              <div className="label">Name</div>
              <input value={payData?.name}  onChange={(e)=>setPayData({...payData, name:e.target.value})} />
            </div>
            <div className="input-content label">
              <div className="label">IPPIS</div>
              <input value={payData?.ippis}  onChange={(e)=>setPayData({...payData, ippis:e.target.value})} />
            </div>
            <div className="input-content label">
              <div className="label">Phone Number</div>
              <input value={payData?.phone}  onChange={(e)=>setPayData({...payData, phone:e.target.value})} />
            </div>
            <div className="input-content">
              <div className="label">Select Site</div>
              <select  className='label border border-dark col-md-10' onChange={(e)=>{  setPayData({...payData, site:e.target.value })}}>
                <option value=''>Show All</option> 
                    <option value="DAMISHI">Damishi</option>
                    <option value="MARABAN JOS">Maraban Jos</option>          
              </select>
            </div>
            <div className="input-content label">
              <div className="label">beacon</div>
              <input value={payData.beacon}  onChange={(e)=>setPayData({...payData, beacon:e.target.value})} />
            </div>
            <div className="input-content label">
              <div className="label">Command</div>
              <input value={payData?.address}  onChange={(e)=>setPayData({...payData, address:e.target.value})} />
            </div>
            
          </div>

        </div>
      </Wrapper>

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyItems: 'stretch',
          justifyContent: 'stretch',
        }}
      >
       
        <Button
          style={{
            background: '#FF4B01',
            color: '#fff',
            fontSize: '8px',
            alignItems: 'center',
            width: 70
          }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default AddCustomer;
