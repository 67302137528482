import React from "react";
import slugs from "../../resources/slugs";

import Icon from "@ant-design/icons";

export default  function getMenuData(logina) {

  let isAdmin = localStorage.getItem('isAdmin')
  let login =  localStorage.getItem('login')  
  //localStorage.removeItem('isAdmin')
  return isAdmin==='true'?[
    {
      title: "Home",
      key: "dashboards",
      url: slugs.dashboard,
    },
     {
      title: "Customers",
      key: "customers",
      url: slugs.customers,
    },
     {
      title: "Plots",
      key: "plots",
      url: slugs.plots,
    },
    {
      title: "For Sale",
      key: "users",
      // roles: ['admin'],
      children: [
        {
          title: "Houses",
          key: "manageUsers",
          url: slugs.users,
        },
        {
          title: "Plots",
          key: "manageUsers",
          url: slugs.users,
        },
      ],
    },   
    {
      title: "For Rent",
      key: "users",
      // roles: ['admin'],
    
    },   
     {
      title: "Sites",
      key: "logs",
      url: slugs.sites,

    }, 
    {
      title: "Payments",
      key: "pay",
      url: slugs.payments,

    },       
    {
      title: "FeedBack",
      key: "feedback",
      url: slugs.feedback,
    },
    login?  {
      title: "Signout",
      key: "signout",
      url: slugs.logout,
    
    }:  {
      title: "Login",
      key: "login",
      url: slugs.login,
    
    },
    
  ]:[
    {
      title: "Home",
      key: "dashboards",
      url: slugs.dashboard,
    },
   
    {
      title: "For Sale",
      key: "users",
      // roles: ['admin'],
      children: [
        {
          title: "Houses",
          key: "manageUsers",
          url: slugs.users,
        },
        {
          title: "Plots",
          key: "manageUsers",
          url: slugs.users,
        },
      ],
    },   
    {
      title: "For Rent",
      key: "users",
      // roles: ['admin'],
    
    },   
     
    {
      title: "FeedBack",
      key: "feedback",
      url: slugs.feedback,
    },
    
    (login)=='true'?
    {
      title: "My profile",
      key: "profile",
      url: slugs.profile,
    }:'',
    
    (login)=='true'?
    {
      title: "Signout",
      key: "signout",
      url: slugs.logout,
    
    }:  {
      title: "Login",
      key: "login",
      url: slugs.login,
    
    }
    
  ];
}
