import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { Modal } from 'antd';
import { saveAs } from 'file-saver'
import { customBaseUrl, httpPost } from '../services/http';
import { hideLoader, showLoader } from '../../utils/loader';

const ViewDoc = ({ showModal, hideModal, title, className }) => {
  const [file, setFile] = useState('');
  const [amount, setAmount] = useState(0);

 

    const downloadImage = () => {
      saveAs(`http://nmszazzauproperties.com.ng/certs/file/${title}.jpg`, 'image.jpg') // Put your image url here.
    }

    

  return (
    <Modal
      className={className}
      title={'Offer  Certificate'}
      centered
      visible={showModal}
      onOk={() => hideModal(false)}
      onCancel={() => hideModal(false)}
      width={500}
      footer={0}
    >


    {/*<a href={`http://nmszazzauproperties.com.ng/certs/file/${title}.jpg`} download>*/}
      
        <div className='col-10 mt-5 mb-3 mx-auto'>

         <img src={`http://nmszazzauproperties.com.ng/certs/file/${title}.jpg`} style={{width:400, heigth:400}}/>
        </div>

       {/*} <div class='mx-auto'>
          <button class='px-6 py-2 text-white bg-red-500 rounded-full baseline hover:bg-red-300 ' type='submit'>
            Download
          </button>
        </div>
        {/*</a>
        <button onClick={downloadImage}>Download!</button>*/}
    </Modal>
  );
};

export default ViewDoc;
