import React, {useEffect, useState} from "react";
import {Table,Row,Col,Card, Button, Modal, Form, Input, Select, message} from "antd";
import Layout from "../Layout"
import axios from "axios";
import { customBaseUrl, httpGet } from "../services/http";
import AntDropdown from "../customDropdown/dropdown";
import BulkUpload from "./bulkModal";
import { hideLoader, showLoader } from "../../utils/loader";
import Swal from "sweetalert2";



const Plots = () => {

    const [customers, setCustomers] = useState([])
    const [showBulkUpload, setShowBulkUpload] = useState(false)
    const [stat, setStat] = useState([]);
    const columns = [
        {
            title: 'Site',
            dataIndex: 'location',
            key: 'site',
        },
        {
            title: 'Plot No',
            dataIndex: 'plotno',
            key: 'plotno',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Block',
            dataIndex: 'block',
            key: 'block',
        
        },
       
       
        {
            title: 'Owner',
            dataIndex: 'customername',
            key: 'owner',
        },
        {
            title: 'Owner Id',
            dataIndex: 'customerid',
            key: 'owner',
            render: (text,row) => <a href={`#/customer/${row?.customerid}`}>{row?.customerid}</a>,

        },
        {
            title: 'Phone No.',
            dataIndex: 'phonenumber',
            key: 'owner',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
       /* {
            title: 'AMount paid',
            dataIndex: 'amountreceived',
            key: 'amntpaid',
        },
       
       /* {
            title: 'Sold on',
            dataIndex: 'soldat',
            key: 'nofoplots',
        },*/
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span>
                    <Button type="primary" onClick={() => editCustomer(record)}>Edit</Button>
                    {//<Button type="danger" onClick={() => deleteCustomer(record)}>Delete</Button>
                    }
                </span>
            ),
        }
    ];
    const editCustomer = (record) => {
        console.log(record)
    }
    const deleteCustomer = (record) => {
        console.log(record)
    }

    const getAllPlots = async()=>{
        showLoader();
        let res = await httpGet(`${customBaseUrl}/api/v1/sites/customerplots`)

           if (res?.status) {
          hideLoader();
            console.log(res.data)
            setCustomers(res.data)
        } else {
          hideLoader();
          Swal.fire('Error',res.message, 'error');
        }
    }

    const getPlotStat = async()=>{
        let res = await httpGet(`${customBaseUrl}/api/v1/sites/plotstat`)

   

        if (res?.status) {
          hideLoader();
            setStat(res.data[0])
        } else {
          hideLoader();
          Swal.fire('Error',res.message, 'error');
        }
    }

    useEffect(() => {
       getAllPlots();
       getPlotStat();
    },[])

    const addOptions = [
        "Add Single",
        "Excel Upload",
      ];
      const handleAddDropdown = (item) => {
        if (item === "Add Single") alert(true);
        if (item === "Excel Upload") setShowBulkUpload(true);
        //if (item === "Send Using Webforms") setShowTransferForms(true);
      };

    return(
        <Layout>
             <div style={{margin:40}}>
                <Row>
                    <Input placeholder="Search" style={{width:'10%',marginLeft:'5%'}}/>
                    <Button type="primary" style={{marginLeft:'10px'}}>Search</Button>
                    <div style={{position:'absolute',  right:15,float:'right'}}>
                        <Button type="primary" style={{marginLeft:'10px'}}>Export</Button>
                        <AntDropdown
                          dropDownOptions={addOptions}
                          handleDropdownAction={handleAddDropdown}
                          >
                        <Button type="primary" style={{marginLeft:'10px'}}>Add New Plot</Button>
                        </AntDropdown>
                    </div>
                </Row>
            </div>
            <div style={{display:'flex', justifyContent:'center'}}>
                    <Col span={6}>
                        <Card>
                                <h1>No. of Plots</h1>  
                                <p><h1>{stat?.count??0}</h1></p>  
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card span={2}>
                                <h1>No. of Plot Taken</h1>  
                                <p><h1 style={{textAlign:'center'}}>0</h1></p>  
                        </Card >
                    </Col>
                    <Col span={6}>
                        <Card>
                                <h1>No. of Plot available</h1>  
                                <p><h1 style={{textAlign:'center'}}>0</h1></p>  
                        </Card>
                    </Col>
                    <Row>
                   {/*} <Col span={6}>
                        <Card >
                                <h1>Total Amount of Plots</h1>  
                                <p><h1 style={{textAlign:'center'}}>0</h1></p>  
                        </Card>
                    </Col><Col span={6}>
                        <Card>
                                <h1>Total Amount Received</h1>  
                                <p><h1 style={{textAlign:'center'}}>0</h1></p>  
                        </Card>
                    </Col>*/}
                </Row>
            </div>
            <div style={{margin:20}}>
                <Table columns={columns} dataSource={customers} />
            </div>
            {
            showBulkUpload && <BulkUpload hideModal={setShowBulkUpload} showModal={showBulkUpload}/>
           }
        </Layout>
    )
}

export default Plots;


