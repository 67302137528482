import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { Modal } from 'antd';
import { customBaseUrl, httpPost, httpPut } from '../services/http';
import { hideLoader, showLoader } from '../../utils/loader';

const UpdateUser = ({ showModal, hideModal, getAll, title, className, record }) => {
  const [file, setFile] = useState('');
  const [amount, setAmount] = useState(0);
  const [ippis, setIppis] = useState('');
  const [postData, setPostData] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    showLoader();
    const {userId} = JSON.parse(localStorage.getItem('profile'))
    let data = {
        ...postData,
        userId: record?.id, 
        adminId: userId
    }

    let res = await httpPut(`${customBaseUrl}/api/v1/customers/updatecustomer`, data)  

    if (res?.status) {
      hideLoader();
      Swal.fire('Done', res?.message, 'success');
      hideModal(false);
      {//getAll();
      }
    } else {
      hideLoader();
      Swal.fire('Error',res.message, 'error');
    }
  };
  useEffect(()=>{
    console.log('record', record)
    setPostData({...postData, ...record});
    setIppis(record?.ippis)
  },[])
  const filterOp = ["DAMISHI", "MARABAN JOS", "KAFIN GANYE"]
  const filterSt = ["DECEASED", "ACTIVE", "INACTIVE", "COMPLETED"]


  return (
    <Modal
      className={className}
      title={'Update Customer Details'}
      centered
      visible={showModal}
      onOk={() => hideModal(false)}
      onCancel={() => hideModal(false)}
      width={300}
      footer={0}
    >
      <form className='flex flex-col mx-auto  ' onSubmit={handleSubmit}>
        <div className='text-center'>
        <h2>{record?.name}</h2>
        </div>
        <div className='col-10 mt-5 mb-3 mx-auto'>
        <label>IPPIS </label>
          <div class='input-group'>
            <input
              type='text'
              required
              value={postData?.ippis}
              className='form-control border input-form'
              id='inputGroupFile02'
              onChange={(e) => setPostData({...postData, ippis:e.target.value})}
            />
           
          </div>
        </div>
        <div className='col-10 mt-2 mb-3 mx-auto'>
        <label>Phone Number </label>
          <div class='input-group'>
            <input
              type='text'
              value={postData?.phoneNumber}
              required
              className='form-control border input-form'
              id='inputGroupFile02'
              onChange={(e) => setPostData({...postData, phoneNumber:e.target.value})}
            />
           
          </div>
        </div>
        <div className='col-10 mt-2 mb-3 mx-auto'>
        <label>PLots Address </label>
          <div class='input-group'>
            <input
              type='text'
              required
              value={postData?.address}
              className='form-control border input-form'
              id='inputGroupFile02'
              onChange={(e) => setPostData({...postData, address:e.target.value})}
            />
           
          </div>
        </div>
        <div className='col-10 mt-2 mb-3 mx-auto'>
        <label>Sites </label>
          <div class='input-group'>
            <select
             className='form-control border input-form'
             required
              value={postData?.site}
              onChange={(e) => setPostData({...postData, site:e.target.value})}
            >
              {filterOp?.map(e=>
                <option value={e}>{e}</option>
                )}
            </select>
           
          </div>
        </div>
        <div className='col-10 mt-2 mb-3 mx-auto'>
        <label>Status </label>
          <div class='input-group'>
            <select
             className='form-control border input-form'
             required
              value={postData?.status}
              onChange={(e) => setPostData({...postData, status:e.target.value})}
            >
              {filterSt?.map(e=>
                <option value={e}>{e}</option>
                )}
            </select>
           
          </div>
        </div>

        <div class='mx-auto'>
          <button class='px-6 py-2 text-white bg-red-500 rounded-full baseline hover:bg-red-300 ' type='submit' >
            Update user
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default UpdateUser;
