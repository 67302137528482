import React from "react";
import { Col, Row, Card, Tag } from 'antd';
import img1 from '../../assets/img7.jpg';
import img2 from '../../assets/img4.jpg';
import img3 from '../../assets/img9.jpg';




const Content = ({title}) => {


    return(
        <>
                <section className="flex flex-col bg-white">
                <h1 className="mx-auto text-4xl font-bold text-center md:text-5xl md:text-left">
                        WHAT WE DO
                </h1>
                    <h4 className="mx-auto text-3xl font-bold text-center md:text-3xl md:text-left">
                        LAND/PROPERTY SALE
                    </h4>
                    <div className="max-w-md text-center mx-auto text-black font-sans text-1xl">
                    At NMS Zazzau International Company Limited and Property Development, we specialize in the sale of lands and houses. We also offer flexible payment plans and incentives to make acquiring your property easier.                    </div>
                    <h3 className="mx-auto text-3xl font-bold text-center md:text-3xl md:text-left">
                    PROPERTY DEVELOPMENT
                </h3>
                    <div className="max-w-md text-center mx-auto text-black font-sans text-1xl">
                    We bring to bear our experience and expertise in developing luxury and state-of-the-art property for sale and for rent.                    </div>
                    <h5 className="mx-auto text-2xl font-bold text-center md:text-3xl md:text-left">
                    REAL ESTATE ADVISORY
                </h5>
                  <div className="max-w-md text-center mx-auto text-black font-sans text-1xl">
                    We draw from our vast and deep knowledge of the Real Estate industry to give our clients the guidance and advisory services they require.                    </div>
        
                    
                    <div class="flex flex-col mt-20 mb-20 text-center md:flex-row">
                    <div class="mx-auto">
                        <h2 className="font-bold text-1xl md:text-2xl font-serif">Affordability</h2>
                        <p className="text-center text-black font-sans">
                        Our Featured properties are designed for individuals with varying levels of purchasing power, therefore making it affordable to all.                        </p>
                    </div>
                    <div class="mx-auto">
                    <h2 className="font-bold text-1xl md:text-2xl font-serif"> Genuity</h2>
                    <p className="text-center text-black font-sans">
                        A credible real estate can make
                        a world of difference knowing that you are parking your money into a property that will give you long standing security.
                    </p>
                    </div>
                    <div class="mx-auto">
                    <h2 className="font-bold text-1xl md:text-2xl font-serif"> High ROI </h2>
                    <p className="text-center text-black font-sans">
                    Successful real estate investors capitalize on good neighbourhood and locations for the best returns on their investment.                        </p>
                    </div>
                    </div>
                    
                </section>
        <Row gutter={12} >
            <Col span={24}>
                <Card title={title} bordered={false}>
                <h1 className="max-w-md text-4xl font-bold text-center md:text-5xl md:text-left">
                OUR PROJECTS</h1>
                    <div style={{width:'50%', margin:'auto', marginBottom:30}}>
                        We have completed over 100 projects in Nigeria.
                    </div>
                    <div class="flex flex-col md:flex-row">
                    <div class="mx-auto">
                    <br/>
                        <p>
                            <img src={img1} alt="img1" style={{width:'100%'}}/>
                            We provide the highest quality, affordable, and sustainable residential development in the Nigeria.
                        </p>
                    </div>
                    <div class="mx-auto">
                        <p>
                        <img src={img2} alt="img1" style={{width:'100%'}}/>

                            We provide the highest quality, affordable, and sustainable commercial development in the Nigeria.
                        </p>
                    </div>
                    <div class="mx-auto">
                    <br/>

                        <p>
                        <img src={img3} alt="img1" style={{width:'100%'}}/>

                            we provide the highest quality, affordable, and sustainable layout in the Nigeria.
                        </p>
                    </div>
                    </div>
                </Card>
            </Col>           
        </Row>
       
        </>
    )
}

export default Content;