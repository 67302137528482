import Layout from '../../components/Layout';
import React from 'react'
import Content from './content';
import { Carousel } from 'antd';
import Img from "../../assets/img5.jpg"
import Img6 from "../../assets/img6.jpg"
import Img7 from "../../assets/img7.jpg"
import D1 from "../../assets/d1.jpg"
import D2 from "../../assets/d2.jpg"
import D3 from "../../assets/d3.jpg"
import CEO from "../../assets/ceo.jpg"
import { Face2Outlined, Man2Outlined, Person, VerifiedUserOutlined } from '@mui/icons-material';




const HomePage = () => {

    return(
        <Layout>
            <section id='hero'>
            <div className=" container flex flex-col-reverse md:flex-row items-center px-6 mx-auto mt-10 space-y-0 md:space-y-0">
                <div className='flex flex-col mb-32 mt-5 space-y-12 md:w-1/2'>
                <h1 className="max-w-md  text-1xl mt-5 font-bold text-center md:text-1xl md:textleft">
                We build affordable homes for all
                NMS Zazzau International Company Limited and Property Development was incorporated in Kaduna state, the company is into real estate, properties ventures business, constructions, mechanical and civil engineering, mass housing development etc. We have a piece of land measuring about 40 hectares at Kakura/Damishi village, Kujama District, Chikun Local Government Area and Maraban Jos in Igabi Local Government Area of Kaduna State. 
        
                </h1>
                <p className="max-w-sm text-center text-darkGrayishBlue md:text-left">
                </p>
                <div className='flex justify-center md:justify-start'>
                    <a
                    href='#'
                    className='p-3 px-6 bg-red-200 pt-2 text-white bg-brightRed rounded-full baseline hover:bg-brightRedLight'
                    >
                    Get Started
                    </a>
                </div>
                </div>
                <div className='w-full md:w-1/2'>
                <Carousel autoplay>
                    <div>
                    <img src={D2} />
                    </div>
                    <div className="mt-30">
                    <img src={Img} />
                    </div>
                    <div>
                    <img src={Img} />
                    </div>
                    <div>
                    <img src={D1} />
                    </div>                       
                    <div>
                    <img src={D3} />
                    </div>
                </Carousel>
                </div>
            </div>

        </section>
        <Content/>
        <section id="management" className='bg-white'>
            <div className="max-w-6xl px-5 mx-auto mt-32 text-center">
                <h2 className="text-4xl font-bold text-center">
                    Executives
                </h2>
                <div className=" container flex flex-col-reverse md:flex-row items-center px-6 mx-auto mt-10 space-y-0 md:space-y-0">
                    <div className='flex flex-col mb-32 space-y-12 md:w-1/2'>
                        <h2 className='text-bold'>Nuruddeen Muhammad Sadau</h2>
                            <p><h4>Magajin Garin Gundumar Makera</h4></p>
                        Ceo
                    </div>
                    <div className='w-full md:w-1/2'>
                        <img src={CEO}/>
                    </div>
                </div>
                <div className="flex flex-col mt-24  md:flex-row md:space-x-6">
                    <div className="flex flex-col mt-10 items-center  p-6 space-y-6 space-x-10 rounded-lg bg-gray-200 md:w-1/3">
                        {//<img src={Img} className='w-60 -mt-14 rounded-full' alt=""/>
                        }
                        <Person size="large" />
                        <h5 className="text-lg font-bold">Malamiyo Ishaq</h5>
                        <p className="text-sm text-darkGrayishBlue">
                        Marketing & Growth
                        </p>
                    </div>
                    <div className="flex flex-col mt-10 items-center p-6 space-y-6 rounded-lg bg-gray-200 md:w-1/3">
                        <Person size="large" />
                        <h5 className="text-lg font-bold">Aliyu Muhammad Auwal</h5>
                        <p className="text-sm text-darkGrayishBlue">
                            Director, ICT
                        <br/>
                        <br/>
                           <br/>
                        </p>
                    </div>
                    <div className="flex flex-col mt-10  items-center p-6 space-y-6 rounded-lg bg-gray-200 md:w-1/3">
                        {// <img src={Img} className='w-60 -mt-14 rounded-full' alt=""/>
                        }   
                        <Person size="large" />
                        <h5 className="text-lg font-bold">Shuaibu Umar</h5>
                        <p className="text-sm text-darkGrayishBlue">
                            Company Manager
                        </p>
                    </div>
                </div>
            </div>
        </section>
        </Layout>
    )
} 

export default HomePage;