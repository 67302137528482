import React, {useEffect, useState} from "react";
import {Table,Row,Col,Card, Button, Modal, Form, Input, Select, message} from "antd";
import Layout from "../Layout"
import axios from "axios";
import AntDropdown from "../customDropdown/dropdown";
import BulkUpload from "./bulkUpload";
import { hideLoader, showLoader } from "../../utils/loader";
import { customBaseUrl, httpGet, httpPost } from "../services/http";
import { FilterList } from "@mui/icons-material";
import UpdateUser from "./updateUser";
import AddCustomer from "../modals/addCustomer";
import Swal from "sweetalert2";



const Customers = () => {

    const [customers, setCustomers] = useState([]);
    const [showBulkUpload, setShowBulkUpload] = useState(false);
    const [stats, setStat] = useState({});
    const [filter, setFilter] = useState('');
    const [showUpdateUser, setShowUpdateUser] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState([]);
    const [postData, setPostData] = useState({});
    const [showAddUser, setShowAddUser] = useState(false);
    const [searchText, setSearchText] = useState("");
    const columns = [
        {
            title: 'User ID',
            dataIndex: 'ippis',
            key: 'email',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text,row) => <a href={`#/customer/${row?.ippis}`}>{row?.name}</a>,
        },
       
        {
            title: 'Phone',
            dataIndex: 'phoneNumber',
            key: 'phone',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Site',
            dataIndex: 'site',
            key: 'active',
        },
        {
            title: 'No. of Plots',
            dataIndex: 'nofoplots',
            key: 'nofoplots',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span>
                    
                    <Button type="primary" onClick={() => {setSelectedCustomer(record); setShowUpdateUser(true)}}>Edit</Button>
                    <Button type="danger" onClick={() => deleteCustomer(record)}>Delete</Button>
                </span>
            ),
        }
    ];
    const editCustomer = (record) => {
        console.log(record)
    }
    const deleteCustomer = (record) => {
        console.log(record)
    }
    const handleSubmit = async () => {
        showLoader();
        let res = await httpPost(`${customBaseUrl}/api/v1/customers/create`,{...postData, updatedBy: 1});
        if (res?.status) {
            hideLoader();
            Swal.fire('Done', res?.message);
            setShowAddUser(false);
            getAll()
        } else {
            hideLoader();
            Swal.fire('Error', res?.message);
        }
    }
    const getAll = async()=>{
        showLoader();
        let res = await httpGet(`${customBaseUrl}/api/v1/customers/all`)
        if(res.status){
            console.log('all', res.data)
            hideLoader();
            setCustomers(res.data)
        }else{
            hideLoader();
        }
    }

    const getStat = async()=>{
        showLoader();
        let res = await httpGet(`${customBaseUrl}/api/v1/customers/stats`)
        if(res.status){
            console.log('all', res.data)
            hideLoader();
            setStat(res.data)
        }else{
            hideLoader();
        }
    }

    useEffect(() => {
        /*
        axios.get('/api/v1/customers/plotcount')
            .then(res => {
                console.log(res.data)
                setCustomers(res.data)
            }).catch(err => {
                console(err)
            }
            )*/
            getStat();
            getAll();
           
    },[])
    const addOptions = [
        "Add Single",
        "Excel Upload",
      ];
      const handleAddDropdown = (item) => {
        if (item === "Add Single") setShowAddUser(true);
        if (item === "Excel Upload") setShowBulkUpload(true);
        //if (item === "Send Using Webforms") setShowTransferForms(true);
      };
      const filterOp = ["ALL","DAMISHI", "MARABAN JOS", "KAFIN GANYE"]

      const handleFilter = (item) => {
        if (item==="ALL"){
             setFilter("")
        }else{
        setFilter(item)
        }
      }
    return(
        <Layout>
             <div style={{margin:40}}>
                <Row>
                   
                    <Input placeholder="Search" onChange={(e)=>setSearchText(e.target.value)} style={{width:'10%',marginLeft:'5%'}}/>
                    <Button type="primary" style={{marginLeft:'10px'}}>Search</Button>
                    <div className="mx-auto">
                    <AntDropdown dropDownOptions={filterOp} handleDropdownAction={handleFilter}>
                    <FilterList/>
                    </AntDropdown>
                    </div>
                    <div style={{position:'absolute',  right:15,float:'right'}}>
                        <Button type="primary" style={{marginLeft:'10px'}}>Export</Button>
                        <AntDropdown
                          dropDownOptions={addOptions}
                          handleDropdownAction={handleAddDropdown}
                          >
                            <Button type="primary" style={{marginLeft:'10px'}}>Add New Customer</Button>
                        </AntDropdown>
                    </div>
                </Row>
            </div>
            <div className="container flex flex-col items-center
           justify-around px-6 py-12 mx-auto space-y-4 md:flex-row md:space-y-0">
            <div>
               
                        <Card>
                                <h1>Total Customers</h1>  
                                <p><h1 className="mx-auto text-center">{stats.allCustomers}</h1></p>  
                        </Card>
        
            </div>
            <div>
                        <Card>
                                <h1>MARABAN JOS</h1>  
                                <p><h1 className="mx-auto text-center">{stats.maraba}</h1></p>  
                        </Card>
                    
            </div>
            <div>
                        <Card>
                                <h1>KAFIN GANYE</h1>  
                                <p><h1 className="mx-auto text-center">{stats.mCity}</h1></p>  
                        </Card>
                    
            </div>
            <div>
                        <Card>
                                <h1>DAMISHI</h1>  
                                <p><h1 className="mx-auto text-center">{stats.damishi}</h1></p>  
                        </Card>
            </div>
            </div>
            <div style={{margin:20}}>
                <Table columns={columns} dataSource={filter?customers.filter(e=>e?.site===filter) :searchText?customers.filter(e=>e?.ippis?.toLowerCase()?.includes(searchText.toLowerCase()) || e?.name?.toLowerCase()?.includes(searchText.toLowerCase())) :customers} />
            </div>
           {
            showBulkUpload && <BulkUpload hideModal={setShowBulkUpload} showModal={showBulkUpload}/>
           }
           {
            showUpdateUser && <UpdateUser getAll={getAll} hideModal={setShowUpdateUser} showModal={showUpdateUser} record={selectedCustomer}/>
           }
           {
            showAddUser && <AddCustomer showModal={showAddUser} hideModal={setShowAddUser} handleSubmit={handleSubmit} setPayData={setPostData} payData={postData} />
           }
        </Layout>
    )
}

export default Customers;


