import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { useNavigate } from "react-router-dom";
import logo from '../../assets/ziconn.png';
import './index.css';
import {
  AppstoreOutlined,
  BarChartOutlined,
  CloudOutlined,
  ShopOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { Button, Layout, Menu } from 'antd';
import getMenuData from "../services/menu";
import AntDropdown from '../customDropdown/dropdown';

const { Header, Content, Footer, Sider } = Layout;
const items = [
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  BarChartOutlined,
  CloudOutlined,
  AppstoreOutlined,
  TeamOutlined,
  ShopOutlined,
].map((icon, index) => ({
  key: String(index + 1),
  icon: React.createElement(icon),
  label: `nav ${index + 1}`,
}));


const { SubMenu } = Menu;


const Index = (props) => {
const { children } = props;
const navigate = useNavigate();
const [showBulkUpload, setShowBulkUpload] = useState(false)

const logout = () => {
  localStorage.setItem('login',false)
  localStorage.setItem('isAdmin',false)
  localStorage.setItem('profile', {})
  navigate('/login')

}
    
const menuData = getMenuData(localStorage.getItem('login'));
const addOptions = [
  "Add Single",
  "Excel Upload",
];
const handleAddDropdown = (item) => {
  if (item === "Add Single") alert(true);
  if (item === "Excel Upload") setShowBulkUpload(true);
  //if (item === "Send Using Webforms") setShowTransferForms(true);
};

return(
  <Layout  className="layout" >
   {/* <Sider
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
      }}
    >
      <div className="logo" />
      <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
        >
          {menuData.map((item) => {
            if (item.children) {
              return (
                <SubMenu key={item.key} title={item.title} icon={item.icon}>
                  {item.children.map((subItem) => {
                    return (
                      <Menu.Item
                        key={subItem.key}
                        onClick={() => navigate(subItem.url)}
                      >
                        {subItem.title}
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              );
            }
            return (
              <Menu.Item
                key={item.key}
                onClick={() => navigate(item.url)}
                icon={item.icon}
              >
                {item.title}
              </Menu.Item>
            );
          })}
        </Menu>
    </Sider>*/}
   
      <Header
        className="site-layout-background"
        
        style={{
         position: 'fixed',
        zIndex: 100,
        width: '100%',
        
        }}
       
      >
        <div style={{justifyContent:'center', alignItems:'center', textAlign:'center'}}>
        <img src={logo} class=" hidden h-15 w-15 md:block"/>
        </div>
          <Menu
          theme="light"
          mode="horizontal"
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
        >
       
          {menuData.map((item) => {
            if (item.children) {
              return (
                <SubMenu key={item.key} title={item.title} icon={item.icon}>
                  {item.children.map((subItem) => {
                    return (
                      <Menu.Item
                        key={subItem.key}
                        onClick={() => navigate(subItem.url)}
                      >
                        {subItem.title}
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              );
            }
            
            return (
              <Menu.Item
                key={item.key}
                onClick={() =>item?.title==='Signout'?logout(): navigate(item.url)}
                icon={item.icon}
              >
                {item.title}
              </Menu.Item>
            );
         
          }) 
          
          }        
           
        </Menu>
     
        </Header>
      <Content
        style={{
       
        padding: '0 0px',
        marginTop: 100,
        }}
      >
        {children}
      
      </Content>
     
  </Layout>
);
    }
export default Index;