import React from "react";
import { MoreOutlined, ProfileOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Card, Table } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import {EditOutlined} from '@ant-design/icons'
import { customBaseUrl, httpGet } from "../services/http";
import Layout from "../Layout"
import AddProfilePic from "../modals/addProfile";
import { hideLoader, showLoader } from "../../utils/loader";
import ViewDoc from "../Customers/viewDoc";



const Profile=()=>{
    const [sites, setSites] = useState([])
    const [payments, setPayments] = useState([])
    const [ showPropicModal, setShowProfilePicModal] = useState(false)
    const [details, setDetails] = useState([])
    const [showCert, setShowCert] = useState(false)
    const [plots, setPlots] = useState([])


    const profile= JSON.parse(localStorage.getItem('profile'))

    const paymentColumns = [
        {
            title: 'Ref',
            dataIndex: 'ref',
            key: 'plotno',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Reason',
            dataIndex: 'element',
            key: 'site',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'price',
        },   
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'date',
        },       
      
        
    ]
    const columns = [
        {
            title: 'Site',
            dataIndex: 'location',
            key: 'site',
            render: (text)=> text==1?'Damishi': text==2?'Maraban Jos':text==3?'Kafin Ganye':text
        },
        {
            title: 'Block',
            dataIndex: 'block',
            key: 'site',
        },
        {
            title: 'Plot No',
            dataIndex: 'plotno',
            key: 'plotno',
            render: text => <a>{text}</a>,
        }        
    ]

   
    const getPayments = async () => {

        let res =await httpGet(`${customBaseUrl}/api/v1/payments/customer/${profile?.ippis}`)
        if(res.status){
            console.log('payment', res.data)
            setPayments(res.data)
        }else{
            console.log(res)
        }
    }

    const getSites = async()=> {
        showLoader();
        let res = await httpGet(`${customBaseUrl}/api/v1/customers/plots/${profile?.ippis}`)
        if(res.status){
            console.log('user plots', res.data)
            hideLoader();
            setPlots(res.data)
        }else{
            hideLoader();
        }
    }
    const getUserInfo = async()=>{
        showLoader();
        let res = await httpGet(`${customBaseUrl}/api/v1/customers/profile/${profile.ippis}`)
        if(res.status){
            console.log('all', res.data)
            hideLoader();
            setDetails(res.data[0])
        }else{
            hideLoader();
        }
    }


    useEffect(()=>{
        getSites();
        getPayments();
        getUserInfo();
    },[])
return (
    <Layout>
        <div className="container flex w-full flex-col bg-gray-100">
                <div className="flex flex-col  bg-[#2D136F]">
                <div className=" flex  item-center bg-white w-20 h-20 mx-auto  mt-10 shadow-2xl rounded-full ">
                        {details?.imgurl?<img className="w-30 h-30 rounded-full " src={details.imgurl}/>:
                        <Avatar shape='circle' size={80}  icon={<UserOutlined />}/>}
                        <div className="z-20 bg-green-500 my-auto mx-auto">
                        <EditOutlined size={100} onClick={()=>setShowProfilePicModal(true)}/>

                    </div>

                    </div>
                    <div  className="item-center text-center  ">
                        <h1 className="text-white">
                            {profile?.fullName}
                        </h1>
                        <h1 className="text-white">
                        +234{profile?.phoneNumber}
                        </h1>
                        <h1 className="text-white">
                            Email@ns.com.ng
                        </h1>
                    </div>
                    
                
                    <marquee direction="left">      
                    <h1 className="text-red-500">        
                        Outstanding Infrastructure Payment N220,000
                    </h1>
                    </marquee>

                </div>

                <div className="container flex flex-col items-center justify-center
            px-6 py-12  space-x-6 mx-auto space-y-4 md:flex-row md:space-y-0">
            <div>
               
                        <Card >
                                <h1>No of Plots</h1>  
                                <p><h1 className="mx-auto text-center">0</h1></p>  
                        </Card>
        
            </div>
           {/*} <div>
                        <Card>
                                <h1>Total Cost of Plots</h1>  
                                <p><h1 className="mx-auto text-center">{0}</h1></p>  
                        </Card>
                    
</div>*/}
            <div>
                        <Card>
                                <h1>Total Amount Paid</h1>  
                                <p><h1 className="mx-auto text-center">{0}</h1></p>  
                        </Card>
                    
            </div>  
           

            </div>
            <div className="ml-20 my-10">
                Document {' '}
            
               <button style={{color:'red'}} onClick={()=>setShowCert(true)}>View</button>

            </div>
                <div >
                    <h1 className="text-2xl ml-10 font-sans">
                        sites
                    </h1>
                    <div className="mx-20">
                    <Table columns={columns} dataSource={plots} />
                    </div>
                </div>
                <div>
                <h1 className="text-2xl ml-10 font-sans">
                        Payments
                    </h1>
                    <div className="mx-20">
                    <Table columns={paymentColumns} dataSource={payments} />
                    </div>
                </div>
        </div>
        {
            showPropicModal &&
            <AddProfilePic getUserInfo={getUserInfo}  details={details} showModal={showPropicModal} hideModal={setShowProfilePicModal} userid={profile?.ippis} name={profile?.name}/>
        }
          {
            showCert &&
            <ViewDoc  title={profile?.ippis} showModal={showCert} hideModal={setShowCert}/>
        }
    </Layout>
)

}

export default Profile;